<template>
  <v-dialog
    :value="dlg.visible"
    width="760"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          class="ql-editor"
          v-html="cntInfo.value"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  export default {
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '用户协议',
          visible: false
        },
        cntInfo: {},
      }
    },
    methods: {
      getContent (type = 'user-agreement') {
        const me = this
        let params = null
        const executed = function (res) {
          if (res.status) {
            const item = params.items[0] || {}
            me.cntInfo = item.content || {}
            me.dlg.title = item.label || '用户协议'
          }
        }

        params = web.dict.getParams({
          type,
          executed
        })

        api.httpx.getItems(params)
      },
      show (type = 'user-agreement') {
        this.getContent(type)
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
    }
  }
</script>
